const firebaseConfig = {
  projectId: "the-2196",
  appId: "1:463562082313:web:70e665a9d1d9eba25a10d8",
  databaseURL:
    "https://the-2196-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "the-2196.appspot.com",
  locationId: "us-central",
  apiKey: "AIzaSyDl9I49kC4fmzCZetSL054VVeco0mPWU-M",
  authDomain: "the-2196.firebaseapp.com",
  messagingSenderId: "463562082313",
  measurementId: "G-36X48BC64P",
};

export default firebaseConfig;
